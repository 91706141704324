<!--
 * @Descripttion:
 * @version:
 * @Author: 张天鹏
 * @Date: 2020-01-02 19:44:31
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-04-26 16:42:56
 -->
<template>
    <div class="contentbox" v-if="!showmainbody">
      <!-- 头部 -->
        <div class="generatet">
          <div class="generatetc">
            <div class="generatel">
              <p>{{form.userinfo.pidsex}}<span>|</span>{{form.userinfo.pidage}}岁</p>
            </div>
            <div class="generater">
                <p @click="tong(form.userinfo.yearnum,'年')"><span>缴费年限：</span>{{form.userinfo.yearnum}}年</p>
                <p @click="tong(form.userinfo.lamount,'万元')"><span>保险金额：</span>{{form.userinfo.lamount}}万元</p>
            </div>
          </div>
        </div>
        <!-- 投保信息 -->
        <div class="generatec">
          <div class="generatecw">
            <div class="generatecl">
            <p class="generateclp">投保信息</p>
            <li>保险期间</li>
            <li>首年保费</li>
            <li>总保费</li>
          </div>
            <md-scroll-view
              ref="scrollView"
              :auto-reflow="true"
              :scrolling-y="false"
              :touch-angle="80"
              :is-prevent="false"
            >
            <div class="liabilitybrfl" :style="{width:form.cplist.length*50+'%'}">
              <div class="generatecr" v-for="(item,index) of form.cplist" :key="index">
                    <div class="generatecrc">
                      <p class="generatecrcp" @click="tong(item.sprodname)">
                        {{item.sprodname}}
                      </p>
                      <li class="generatecrcpli">{{item.endnum=='' ? '-': item.endnum}}</li>
                      <li :class="item.initmoney=='' ? 'generatecrcpli':''">{{item.initmoney=='' ? '-': item.initmoney}}</li>
                      <li :class="item.initmoney=='' ? 'generatecrcpli':''">{{item.zxmount=='' ? '-': item.zxmount}}</li>
                    </div>
                  </div>
            </div>
            </md-scroll-view>

          </div>
        </div>
        <!-- 保险责任 -->
        <div class="liability">
          <div class="liabilityt" @click="coverage('coverage')">
            <div class="liabilitytl">
              <p>保险责任</p>
            </div>
            <div class="liabilitytr">
              <p v-if="!showcoverage"><span>展开</span><img src="@/assets/jhs/img/next.png" alt=""></p>
              <p v-if="showcoverage"><span>收起</span><img src="@/assets/jhs/img/down.png" alt=""></p>
            </div>
          </div>
          <div class="liabilityb" v-if="showcoverage">
              <div class="liabilitybw">
                <div class="liabilitybl">
                  <div class="liabilityblt">
                    <p class="liabilitybp"></p>
                  </div>
                  <div class="liabilityblb" v-for="(ic,i) of dataform" :key="i">
                    <div class="liabilityblbl">
                      <p class="liacolor" @click="tong(ic.bname)">{{ic.bname.length/2>ic.compareList.length?ic.bname.slice(0,ic.compareList.length*2):ic.bname}}<span v-if="ic.bname.length/2>ic.compareList">...</span></p>
                    </div>
                    <div class="liabilityblbr">
                      <li class="liacolor" v-for="(it,index) of ic.compareList" :key="index" @click="tong(it.sname)">{{it.sname}}</li>
                    </div>
                  </div>
                </div>
                  <md-scroll-view
                    ref="scrollView"
                    :auto-reflow="true"
                    :scrolling-y="false"
                    :touch-angle="80"
                    :is-prevent="false"
                   >
                   <div class="liabilitybrfl" :style="{width:form.cplist.length*50+'%'}">
                     <div v-for="(ie,index) in form.compareList" :key="index">
                       <div class="liabilitybr" v-for="(item,index) in form.compareList[index]" :key="index">
                          <div class="liabilitybrc">
                            <p class="liabilitybrcp" @click="tong(index)">{{index}}</p>
                            <div v-for="(ic,i) of item" :key="i" class="liabilitybrcptop">
                              <div v-for="(it,index) of ic.compareList" :key="index">
                                <li class="liabilitybrcpli">{{!it.bzcontent ? '-': it.bzcontent}}</li>
                              </div>
                            </div>
                          </div>
                        </div>
                     </div>
                   </div>
                  </md-scroll-view>
              </div>
          </div>
        </div>
        <!-- 基本责任 -->
        <div class="liability">
          <div class="liabilityt" @click="coverage('basic')">
            <div class="liabilitytl">
              <p>基本信息</p>
            </div>
            <div class="liabilitytr">
              <p v-if="!showbasic"><span>展开</span><img src="@/assets/jhs/img/next.png" alt=""></p>
              <p v-if="showbasic"><span>收起</span><img src="@/assets/jhs/img/down.png" alt=""></p>
            </div>
          </div>
           <div class="liabilityb" v-if="showbasic">
              <div class="liabilitybw">
                  <div class="generatecl">
                    <li>产品简称</li>
                    <li>注册资本</li>
                    <li>客服电话</li>
                    <li>保险区间</li>
                  </div>
                   <md-scroll-view
                    ref="scrollView"
                    :auto-reflow="true"
                    :scrolling-y="false"
                    :touch-angle="80"
                    :is-prevent="false"
                   >
                   <div class="liabilitybrfl" :style="{width:form.cplist.length*50+'%'}">
                      <div class="generatecr" v-for="(item,index) in form.cplist" :key="index">
                        <div class="generatecrc">
                          <li class="generatecrcpli">{{item.comname=='' ? '-': item.comname}}</li>
                          <li class="generatecrcpli">{{item.ziben=='' ? '-': item.ziben}}</li>
                          <li class="generatecrcpli">{{item.kftel=='' ? '-': item.kftel}}</li>
                          <li class="generatecrcpli">{{item.bxqj=='' ? '-': item.bxqj}}</li>
                        </div>
                      </div>
                    </div>
                  </md-scroll-view>
                </div>
              </div>
          </div>
          <!-- 产品亮点 -->
          <div class="liability">
            <div class="liabilityt" @click="coverage('product')">
              <div class="liabilitytl">
                <p>产品亮点</p>
              </div>
              <div class="liabilitytr">
                <p v-if="!showproduct"><span>展开</span><img src="@/assets/jhs/img/next.png" alt=""></p>
                <p v-if="showproduct"><span>收起</span><img src="@/assets/jhs/img/down.png" alt=""></p>
              </div>
            </div>
           <div class="liabilityb" v-if="showproduct">
              <div class="liabilitybw">
                  <div class="generatecl">
                    <li></li>
                    <li v-if="form.isuser">修改信息</li>
                    <li>产品亮点</li>
                  </div>
                   <md-scroll-view
                    ref="scrollView"
                    :auto-reflow="true"
                    :scrolling-y="false"
                    :touch-angle="80"
                    :is-prevent="false"
                   >
                   <div class="liabilitybrfl" :style="{width:form.cplist.length*50+'%'}">
                      <div class="generatecr" v-for="(item,index) in form.cplist" :key="index">
                        <div class="generatecrc">
                          <li class="generatecrcpli" @click="tong(item.sprodname)">{{item.sprodname}}</li>
                          <li v-if="form.isuser"><md-button type="primary" icon="edit" @click="editdata(item)" :style="{'backgroundColor':theme}"></md-button></li>
                          <li class="generatecrcpli" @click="isPopuplook(item.merit)">{{item.merit=='' ? '-': item.merit}}</li>
                        </div>
                      </div>
                    </div>
                  </md-scroll-view>
                    <md-popup v-model="isPopupShowce">
                      <div class="isPopuplook">
                        <p class="lightspot">产品亮点</p>
                        <p class="lightspotcon">{{this.isPopuplookvalue}}</p>
                      </div>
                    </md-popup>
                    <md-popup
                      v-model="isPopupShow"
                      position="bottom"
                    >
                      <md-popup-title-bar
                        title="修改产品亮点"
                        ok-text="确认"
                        cancel-text="取消"
                        large-radius
                        @confirm="editconfirm"
                        @cancel="okcancel"
                      ></md-popup-title-bar>
                      <div>
                         <md-field>
                          <md-input-item
                              clearable
                              title="产品亮点"
                              class="require"
                              v-model="lightspot.merit"
                              placeholder="请填写产品亮点"
                              align="right"
                           ></md-input-item>
                          </md-field>
                      </div>
                    </md-popup>
                </div>
              </div>
          </div>
          <!-- 条款 -->
          <div class="liability">
            <div class="liabilityt" @click="coverage('clause')">
              <div class="liabilitytl">
                <p>条款</p>
              </div>
              <div class="liabilitytr">
                <p v-if="!showclause"><span>展开</span><img src="@/assets/jhs/img/next.png" alt=""></p>
                <p v-if="showclause"><span>收起</span><img src="@/assets/jhs/img/down.png" alt=""></p>
              </div>
            </div>
            <div class="liabilityb clause" v-if="showclause">
               <div class="safeguard-bomp" v-for="(term,i) of form.tklist" :key="i">
                  <p class="markerr" @click="markerpdf(term)" v-if="term.tkname"><img src="@/assets/jhs/img/pdff.png" />{{term.tkname}}<span class="markerpdf" v-if="term.tkurl"><img src="@/assets/jhs/img/next.png" alt=""></span></p>
               </div>
            </div>
          </div>
          <!-- 底部 -->
            <div class="plan-bom">
                <div class="plan-bomcen">
                    <div class="plan-bomcen-cen">
                        <div class="plan-bomcen-img">
                            <img :src="form.ywyinfo.headimg" alt="">
                        </div>
                        <div class="plan-bomcen-txt">
                            <p>{{form.ywyinfo.cardempname}}</p>
                        </div>
                        <div class="plan-bomcen-btn">
                            <p>{{form.ywyinfo.cardmobile}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="broadside" v-if="fromwhere">
              <div class="iconc" v-if="fromwhere" @click="transmit">
                <span class="iconfont iconiconfontfenxiang2"></span>
              </div>
            </div>
            <div>
               <md-toast ref="toast">
                  {{dong}}
              </md-toast>
            </div>
        </div>
    </div>
    <div v-else class="showmainbodyc">
        <span @click="showmainbody=false" class="close"><img src="@/assets/jhs/img/cancel.png"/></span>
        <pdf v-for="i in numPages"
                  :key="i"
                  :page="i" :src="pdfUrl" style="width: 100%; height: auto;" @num-pages="pageCount=$event"></pdf>
    </div>
</template>
<script>
import pdf from 'vue-pdf'
import { showresport, setMerit } from '@/api/jhs/productlist/index'
import { getStorage, stopScroll, canScroll, AppShare, isAndroid } from '@/lib/util'
import { Toast, ScrollView, Tip } from 'mand-mobile'
import { cloneDeep } from 'lodash'
import { wechatshare } from '@/lib/wechat_share'
import { DoRecord } from '@/api/abt/customerOperation/common/index'
import initWebSocket from '@/mixins/websock'
import config from '@/config'
const { redirect_uri, cvu } = config
export default {
  components: {
    pdf,
    [ScrollView.name]: ScrollView,
    [Tip.name]: Tip,
    [Toast.component.name]: Toast.component
  },
  mixins: [initWebSocket],
  watch: {
    isPopupShowce (val) {
      if (val) {
        stopScroll()
      } else {
        canScroll()
      }
    },
    '$route.name' (nVal) {
      const judgedr = navigator.userAgent.indexOf('dongrui')
      if (isAndroid() && judgedr != -1) {
        window.JYTitleInterface.openPage()
      }
    }
  },
  data () {
    return {
      dong: '',
      pdfUrl: '',
      picurl: '',
      pageCount: 0,
      numPages: 0,
      msossurl: '',
      form: {
        userinfo: {
          pidsex: '',
          pidage: '',
          yearnum: '',
          lamount: ''
        },
        cplist: [],
        ywyinfo: {
          headimg: '',
          cardempname: '',
          cardmobile: ''
        }
      },
      // 微信转发 start
      user: {},
      empno: '',
      rytype: '',
      suserid: '',
      fromwhere: '',
      //  微信转发 end
      showcoverage: true, // 保险责任展开关闭
      showbasic: true, // 基本信息展开关闭
      showproduct: true, // 产品亮点展开关闭
      showclause: true, // 产品亮点展开关闭
      isPopupShow: false, // 产品亮点修改信息弹框
      flag: false,
      showmainbody: false,
      isPopupShowce: false,
      isPopuplookvalue: '',
      lightspot: {}, // 亮点内容
      dataform: [
        {
          bname: '',
          compareList: []
        }
      ]
    }
  },
  created () {
    this.theme = getStorage('theme', '')
    this.msossurl = getStorage('msossurl', '')
    this.fromwhere = this.$route.query.fromwhere ? this.$route.query.fromwhere : ''
    this.user = getStorage('u_s', {})
    this.empno = this.$route.query.empno ? this.$route.query.empno : this.user.empno
    this.qempno = this.$route.query.qempno ? this.$route.query.qempno : this.user.qempno
    this.rytype = this.$route.query.rytype ? this.$route.query.rytype : this.user.rytype
    this.suserid = this.$route.query.suserid ? this.$route.query.suserid : this.user.userid
    if (!this.$route.query.type) {
      showresport({ jhsno: this.$route.query.jhsno, comid: this.user.comid, empno: this.user.empno }).then(res => {
        this.flag = res.data.flag
        this.form = res.data.data
        this.dataform = []
        for (let item in this.form.compareList) {
          for (let ic in this.form.compareList[item]) {
            for (let ie in this.form.compareList[item][ic]) {
              this.dataform.push(this.form.compareList[item][ic][ie])
            }
          }
          break
        }
        let fxstr = {
          suserid: this.suserid,
          empno: this.empno,
          otype: '2',
          btagcode: '16',
          rytype: this.rytype,
          scmap: {
            prodname: this.form.tklist[0].prodname,
            jhsno: this.$route.query.jhsno
          }
        }
        let shareurl = `${redirect_uri}/generate?jhsno=${this.$route.query.jhsno}&empno=${this.empno}&recordtype=16&suserid=${this.user.userid}&rytype=${this.rytype}`
        if (this.fromwhere !== 'app') {
          this.behaviorRecord()
          wechatshare(
            this.form.tklist[0].prodname + '产品对比报告',
            '您的好友' + this.user.nickname + '邀请您查看' + this.form.tklist[0].prodname + '产品对比报告',
            this.user.headimg,
            encodeURI(shareurl),
            redirect_uri,
            fxstr
          )
        }
      })
    } else {
      this.getData()
    }
  },
  methods: {
    transmit () { // 转发
      const jsonstring = {
        url: `${redirect_uri}/generate?jhsno=${this.$route.query.jhsno}&empno=${this.empno}&recordtype=16&suserid=${this.user.userid}&rytype=${this.rytype}`,
        title: this.form.tklist[0].prodname + '产品对比报告',
        description: `您的好友【${this.user.empname}】邀请您查看${this.form.tklist[0].prodname}产品对比报告`,
        logoUrl: ''
      }
      AppShare(jsonstring)
    },
    getData () {
      showresport({ jhsno: this.$route.query.jhsno, comid: this.$route.query.comid }).then(res => {
        this.flag = res.data.flag
        this.form = res.data.data
        this.dataform = []
        for (let item in this.form.compareList) {
          for (let ic in this.form.compareList[item]) {
            for (let ie in this.form.compareList[item][ic]) {
              this.dataform.push(this.form.compareList[item][ic][ie])
            }
          }
          break
        }
      })
    },
    getdatada () {
      showresport({ jhsno: this.$route.query.jhsno, comid: this.user.comid, empno: this.user.empno }).then(res => {
        this.flag = res.data.flag
        this.form = res.data.data
        this.dataform = []
        for (let item in this.form.compareList) {
          for (let ic in this.form.compareList[item]) {
            for (let ie in this.form.compareList[item][ic]) {
              this.dataform.push(this.form.compareList[item][ic][ie])
            }
          }
          break
        }
      })
    },
    isPopuplook (val) {
      if (val != '') {
        this.isPopuplookvalue = val
        this.isPopupShowce = true
      }
    },
    async loadPdfHandler () {
      this.pdfUrl = pdf.createLoadingTask(this.markerpdfurl)
      this.pdfUrl.then(pdf => {
        this.numPages = pdf.numPages
      })
    },
    markerpdf (val) { // 下载pdf
      this.markerpdfurl = cvu + val.tkurl
      this.numPages = 0
      this.loadPdfHandler()
      this.showmainbody = true
    },
    coverage (val) {
      if (val === 'coverage') {
        this.showcoverage = !this.showcoverage
      } else if (val === 'basic') {
        this.showbasic = !this.showbasic
      } else if (val === 'product') {
        this.showproduct = !this.showproduct
      } else if (val === 'clause') {
        this.showclause = !this.showclause
      }
    },
    editdata (val) {
      let data = cloneDeep(val)
      this.lightspot = data
      this.isPopupShow = true
    },
    editconfirm () { // 确定
      let data = {
        comid: this.user.comid,
        cplist: [{
          prodcode: this.lightspot.prodcode,
          merit: this.lightspot.merit
        }],
        jhsno: this.$route.query.jhsno
      }
      setMerit(data).then(res => {
        this.getdatada()
        this.isPopupShow = false
        Toast.succeed('修改成功')
      })
    },
    tong (val, str) {
      if (str) {
        this.dong = val + str
      } else {
        this.dong = val
      }
      this.$nextTick(() => {
        this.$refs.toast.show()
        setTimeout(() => {
          this.$refs.toast.hide()
        }, 3000)
      })
    },
    okcancel () { // 取消
      this.isPopupShow = false
    },
    behaviorRecord () {
      let data = {
        suserid: this.suserid,
        empno: this.rytype == 'W' ? this.empno : this.qempno,
        otype: '1',
        btagcode: '16',
        rytype: this.rytype,
        scmap: {
          prodname: this.form.tklist[0].prodname,
          jhsno: this.$route.query.jhsno
        }
      }
      DoRecord(data).then(res => {
        let id = res.data.data.id
        let comid = this.user.comid
        this.initWebSocket(id, comid) // 开启websocket连接
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
    $widthou = 200%
    .contentbox{
        width 100%
        overflow-x hidden
        background #f6f6f6
      }
    .generatet{
        width 96%
        height 15vh
        background url('~@/assets/jhs/img/ban.png') no-repeat
        background-size 100%
        margin: 30px 20px
        color: #fff
    }
    .generatetc{
      width 80%
      margin 0 auto
    }
    .generatel{
      width 100%
      height 7.5vh
      line-height 7.5vh
    }
    .generatel p{
      font-size 45px
      font-weight 550
    }
    .generatel span{
      display inline-block
      padding 0 35px
      color: #89c0e9
    }
    .generater{
      height 50%
      width 100%
      display flex
    }
    .generater span{
      font-size 40px
      color: #89c0e9
    }
    .generater p{
      width 50%
      font-size 45px
      white-space nowrap
      text-overflow ellipsis
      overflow hidden
    }
    .generatec{
      padding-bottom 30px
      border-bottom 20px solid #fff
    }
    .generatecw{
      display flex
      width 95%
      margin 0 auto
    }
    .generatecl{
      width 30%
      text-align center
    }
    .generateclp,.liabilitytl p{
      color #525e74
      font-size 45px
      font-weight 700
      text-align left
      padding-left 30px
      height 90px
      line-height 90px
    }
    .generateclp{
      background #fff
    }
    .generatecl li{
      color #525e74
      font-weight 700
      height 90px
      line-height 90px
      background #f6f6f6
    }
    .generatecr,.liabilitybr{
       width 300px
       text-align center
       margin-left 30px
       box-shadow 0 0px 11px -2px #ccc
    }
    .generatecrcp,{
      color #fcfdff
      font-size 40px
      font-weight 500
      height 90px
      line-height 90px
      background #3a6bba
      border-radius 20px 20px 0 0
      white-space nowrap
      text-overflow ellipsis
      overflow hidden
      padding 0 20px
    }
    .generatecrc{
      border-radius 20px 20px 0 0
      background #fff
    }
    .generatecrc li{
      color #3a6abc
      font-weight 700
      height 90px
      line-height 90px
      white-space nowrap
      text-overflow ellipsis
      overflow hidden
    }
    .md-scroll-view{
      width 70%
    }
    .liabilitybrfl{
      display flex
    }
    .generatecrc .generatecrcpli{
      color #25262a
      font-weight 400
    }
    .liability{
      background #fff
      margin 30px 0
      width 100%
    }
    .liabilityt{
      width 95%
      margin 0 auto
      display flex
      justify-content space-between

    }
    .liabilitytr p{
      height 90px
      line-height 90px
      color #bfbfbf
    }
    .liabilitytr img{
      padding-top 10px
      width 40px
      height 40px
    }
    .liabilityb{
       width 95%
       margin 0 auto
    }
    .clause{
       width 90%
       margin 0 auto
    }
    .liabilitybl{
      width 30%
      text-align center
    }
    .liabilitybp{
      height 90px
      line-height 90px
      background #f6f6f6
    }
    .liabilityblb{
      margin 15px 0
      display flex
      background #f6f6f6
      border-bottom 1px solid #f6f6f6
    }
    .liabilityblbl{
      width 30%
      writing-mode tb-rl
    }
    .liabilityblbr{
      width 70%
    }
    .liacolor{
      color #53677f
      font-weight 700
    }
    .liabilityblbr li,.liabilitybrc li{
      height 100px
      line-height 100px
      white-space nowrap
      text-overflow ellipsis
      overflow hidden
    }
    .liabilitybrcptop{
      margin 15px 0
      border-bottom 1px solid #efefef
    }
    .liabilitybw{
      display flex
    }
    .liabilitybrcp{
      font-size 40px
      font-weight 500
      height 100px
      line-height 100px
      background #f6f6f6
      white-space nowrap
      text-overflow ellipsis
      overflow hidden
      padding 0 20px
    }
    .safeguard-bomp{
      position relative
      margin: 30px 0
  }
  .safeguard-bomp .markerpdf{
      width 50px
      height 50px
      line-height 50px
      float right
      color #238acf
  }
  .marker{
    padding 30px 0
  }
  .close img{
      float right
      height 90px
      width 90px
  }
  .plan-bom{
      height 120px
      background #fff
  }
  .plan-bomcen{
      width 90vw
      height 120px
      margin 0 auto
      position relative
  }
  .plan-bomcen-cen {
      width 100%
      height 100px
      display flex
      position absolute
      top 50%
      margin-top -50px
  }
  .plan-bomcen-img{
      width 14%
      height 100%
      display flex
      align-items:center; /*实现垂直居中*/
  }
  .plan-bomcen-txt{
      height 100%
      width 80%
      display flex
      align-items:center; /*实现垂直居中*/
  }
  .plan-bomcen-txt p{
      padding-left 20px
      overflow hidden
      white-space nowrap
      text-overflow ellipsis
  }
  .plan-bomcen-btn{
      height 100%
      padding-top 5px
      width 50%
      display flex
      align-items:center; /*实现垂直居中*/
  }
  .plan-bomcen-btn p{
      text-align center
  }
  .plan-bomcen-img img{
      width 80px
      height 80px
      border-radius 50%
  }
  .markerr img{
    float left
    width 50px
    height 50px
    line-height 50px
  }
  .showmainbodyc{
    background #fff
    height 100vh
  }
  .md-popup .md-field{
    padding 0.4rem 0.4rem 3.4rem
  }
  .isPopuplook{
    background #fff
    width 600px
    height 400px
  }
  .lightspot{
    text-align center
    height 80px
    line-height 80px
  }
  .lightspotcon{
    margin 0 auto
    width 90%
    height 300px
    overflow-y auto
    word-break normal
  }
  .broadside{
      cursor pointer
      position fixed
      top 50%
      width 8vw
      z-index 999
      background color-primary
      text-align center
      border-radius 15px 0px 0px 15px
      right 0
  }
  .broadside::after{
    content: '分享'
    color white
    display block
    display flex
    justify-content center
    align-items center
    border-radius  0 0 0 15px
    width 8vw
    font-size 30px
    background-color color-primary
}
  .iconc{
    color #fff
    padding 15px 0
    text-align center
  }
</style>
